import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Heading,
  Text,
  Textarea,
  List,
  ListItem,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInputField,
  NumberInputStepper,
  NumberInput,
  FormControl,
  Radio,
  Stack,
  RadioGroup,
  Checkbox,
  CheckboxGroup,
  useToast,
  theme,
  Input,
  InputGroup,
  InputRightAddon,
  Divider,
} from "@chakra-ui/react";
import { KeyboardEventHandler, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import Creatable from "react-select/creatable";
import Switch from "react-switch";

import "react-datepicker/dist/react-datepicker.css";
import "./date-picker.css";

import {
  baseURL,
  Flag,
  Form,
  FormField,
  FormType,
  useServiceRecipients,
  useUserInfo,
} from "../../api";
import BulletedTextArea from "../../components/bulleted-text-area";
import { xor } from "lodash";
import axios from "axios";
import { CloseIcon } from "@chakra-ui/icons";
import { auth } from "../../context/firebase";

export function CreateFormModal({
  onClose,
  isOpen,
  form,
  onFormSubmitted,
}: {
  isOpen: boolean;
  onClose: () => void;
  onFormSubmitted: () => void;
  form: Form | undefined;
}) {
  const toast = useToast();

  const {
    isLoading: loadingRecipients,
    error: errorRecipients,
    data: dataRecipients,
  } = useServiceRecipients();

  const recipient = dataRecipients?.find(
    (item) => item.id === form?.service_recipient_id
  );

  const [isSaving, setSaving] = useState(false)
  const [state, setState] = useState<{ [key: string]: any }>({});
  const [flagAsImportant, setFlagAsImportant] = useState(false);

  useEffect(() => {
    if (!form) return;
    if (!form?.fields) return;

    let nextState: any = {};
    form?.fields?.forEach((field) => {
      nextState[field.id.toString()] = null;
    });

    setState(nextState);
  }, [form]);

  const onChange = (id: string, val: any) => {
    setState((prev) => ({
      ...prev,
      [id]: val,
    }));
  };

  const onSubmit = async () => {
    setSaving(true)
    try {
      const token = await auth.currentUser?.getIdToken()

      const postData = {
        form_id: form?.id,
        flagged_as_important: flagAsImportant,
        fields: form?.fields.map((field) => {
          if (field.type === FormType.Signatures) {
            return {
              ...field,
              value: state[field.id]
                ?.map((item: { value: string }) => item?.value)
                ?.join("%@")
                ?.toString(),
            };
          }

          if (field.type === FormType.Multiple_Selection_List) {
            return {
              ...field,
              value: state[field.id]?.join("%@")?.toString(),
            };
          }

          return {
            ...field,
            value: state[field.id]?.toString(),
          };
        }) as FormField[],
      };

      let isValid = true;
      let fieldName = "";
      postData.fields.forEach((field) => {
        if (field.flags.includes(Flag.required) && field.value === null) {
          isValid = false;
          fieldName = field.name;
        }
      });

      if (!isValid) {
        onClose();

        toast({
          title: "Form not created.",
          description: `Field ${fieldName} is required for you to submit this form`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });

        return;
      }

      await axios.post(baseURL + "/api/forms/saveEntryWeb",
        postData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      onClose();

      toast({
        title: "Form entry created.",
        description: "We've created your entry for you.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });

      onFormSubmitted();
    } catch (e) { }
    setSaving(false)
  };

  return (
    <Modal
      onClose={onClose}
      size={"xl"}
      isOpen={isOpen}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{form?.name}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Heading size={"sm"}>
            {recipient?.first + " " + recipient?.last}
          </Heading>
          <Text marginBottom={"3rem"}>{form?.config?.instructions}</Text>

          <List spacing={6}>
            {form?.fields?.map((field, i) => {
              return (
                <Field
                  field={field}
                  key={i}
                  value={state[field.id]}
                  onChange={onChange}
                />
              );
            })}

            <ListItem flexDirection={"row"}>
              <Text size={"sm"} marginBottom=".5rem">
                Flag as important
              </Text>
              <Switch
                onChange={(val) => setFlagAsImportant(val)}
                checked={flagAsImportant}
              />
            </ListItem>
          </List>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onSubmit} colorScheme="blue" mr={3} disabled={isSaving}>
            {isSaving ? "Saving..." : "Create Entry"}
          </Button>
          <Button onClick={onClose}>Close</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

const Field = ({
  field,
  value,
  onChange,
}: {
  field: FormField;
  value: any;
  onChange: (id: string, val: any) => void;
}) => {
  useEffect(() => {
    if (field?.flags?.includes(Flag.default) && field?.type !== FormType.Date) {
      onChange(field?.id, field?.defaultValue);
    }
  }, [field?.id]);

  switch (field.type) {
    case FormType.Header:
      return (
        <Heading fontWeight={"bold"} mt="4">
          {field?.name}
        </Heading>
      );

    case FormType.Separator:
      return <Divider orientation="horizontal" />;

    case FormType.Text:
      return (
        <TextField
          {...field}
          value={value}
          onChange={
            field.flags.includes(Flag.not_kiosk_editable) ? () => { } : onChange
          }
        />
      );
    case FormType.Number:
      return (
        <NumberField
          {...field}
          value={value}
          onChange={
            field.flags.includes(Flag.not_kiosk_editable) ? () => { } : onChange
          }
        />
      );
    case FormType.True_False:
      return (
        <TrueFalseField
          {...field}
          value={value}
          onChange={
            field.flags.includes(Flag.not_kiosk_editable) ? () => { } : onChange
          }
        />
      );
    case FormType.Yes_No:
      return (
        <YesNoField
          {...field}
          value={value}
          onChange={
            field.flags.includes(Flag.not_kiosk_editable) ? () => { } : onChange
          }
        />
      );
    case FormType.Date:
      return (
        <DateField
          {...field}
          value={value}
          onChange={
            field.flags.includes(Flag.not_kiosk_editable) ? () => { } : onChange
          }
        />
      );
    case FormType.List:
      return (
        <ListField
          {...field}
          value={value}
          onChange={
            field.flags.includes(Flag.not_kiosk_editable) ? () => { } : onChange
          }
        />
      );
    case FormType.Multiple_Selection_List:
      return (
        <MultiListField
          {...field}
          value={value}
          onChange={
            field.flags.includes(Flag.not_kiosk_editable) ? () => { } : onChange
          }
        />
      );
    case FormType.Signature:
      return (
        <SignatureField
          {...field}
          value={value}
          onChange={
            field.flags.includes(Flag.not_kiosk_editable) ? () => { } : onChange
          }
        />
      );
    case FormType.Signatures:
      return (
        <SignaturesField
          {...field}
          value={value}
          onChange={
            field.flags.includes(Flag.not_kiosk_editable) ? () => { } : onChange
          }
        />
      );
    default:
      return null;
  }
};

const TextField = ({
  id,
  name,
  value,
  onChange,
  flags,
}: {
  flags: Flag[];
  id: string;
  name: string;
  value: any;
  onChange: (id: string, val: any) => void;
}) => {
  const { isLoading, error, data } = useUserInfo();

  useEffect(() => {
    if (flags.includes(Flag.user_id) && data?.id) {
      onChange(id, data.id);
    }

    if (flags.includes(Flag.user_name) && data?.first && data?.last) {
      onChange(id, data?.first + " " + data?.last);
    }
  }, [data]);

  if (flags.includes(Flag.hidden_from_kiosk)) return null;

  return (
    <ListItem>
      <Text
        size={"sm"}
        marginBottom=".5rem"
        fontWeight={flags.includes(Flag.bold) ? "bold" : "normal"}
      >
        {name}
        {flags.includes(Flag.required) && <span color="red">*</span>}
      </Text>
      {flags.includes(Flag.bulleted) ? (
        <BulletedTextArea
          values={value || []}
          onChange={(e) => {
            onChange(id, e);
          }}
        />
      ) : (
        <Textarea
          value={value || ""}
          onChange={(e) => {
            onChange(id, e.target.value);
          }}
          placeholder="Fill text here."
        />
      )}
    </ListItem>
  );
};

const NumberField = ({
  id,
  name,
  value,
  onChange,
  flags,
}: {
  flags: Flag[];
  id: string;
  name: string;
  value: any;
  onChange: (id: string, val: any) => void;
}) => {
  if (flags.includes(Flag.hidden_from_kiosk)) return null;

  return (
    <ListItem>
      <Text size={"sm"} marginBottom=".5rem">
        {name}
        {flags.includes(Flag.required) && <span color="red">*</span>}
      </Text>
      <NumberInput
        defaultValue={15}
        value={value || ""}
        onChange={(valueAsString, valueAsNumber) => onChange(id, valueAsNumber)}
      >
        <NumberInputField />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
    </ListItem>
  );
};

const TrueFalseField = ({
  id,
  name,
  value,
  onChange,
  flags,
}: {
  flags: Flag[];
  id: string;
  name: string;
  value: any;
  onChange: (id: string, val: any) => void;
}) => {
  if (flags.includes(Flag.hidden_from_kiosk)) return null;

  return (
    <ListItem>
      <Text size={"sm"} marginBottom=".5rem">
        {name}
        {flags.includes(Flag.required) && <span color="red">*</span>}
      </Text>
      <Switch
        offColor={theme.colors.gray[200]}
        onColor={theme.colors.blue[500]}
        borderRadius={6}
        uncheckedIcon={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              fontSize: 11,
              color: theme.colors.gray[600],
              width: "110%",
            }}
          >
            False
          </div>
        }
        checkedIcon={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              fontSize: 10,
              color: theme.colors.white,
              width: "100%",
            }}
          >
            True
          </div>
        }
        onChange={(val) => onChange(id, val)}
        checked={!!value}
      />
    </ListItem>
  );
};

const YesNoField = ({
  id,
  name,
  value,
  onChange,
  flags,
}: {
  flags: Flag[];
  id: string;
  name: string;
  value: any;
  onChange: (id: string, val: any) => void;
}) => {
  if (flags.includes(Flag.hidden_from_kiosk)) return null;

  return (
    <ListItem>
      <Text size={"sm"} marginBottom=".5rem">
        {name}
        {flags.includes(Flag.required) && <span color="red">*</span>}
      </Text>
      <Switch
        offColor={theme.colors.gray[200]}
        onColor={theme.colors.blue[500]}
        borderRadius={6}
        uncheckedIcon={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              fontSize: 11,
              color: theme.colors.gray[600],
              width: "110%",
            }}
          >
            No
          </div>
        }
        checkedIcon={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              fontSize: 10,
              color: theme.colors.white,
              width: "100%",
            }}
          >
            Yes
          </div>
        }
        onChange={(val) => onChange(id, val)}
        checked={!!value}
      />
    </ListItem>
  );
};

const DateField = ({
  id,
  name,
  value,
  onChange,
  flags,
}: {
  flags: Flag[];
  id: string;
  name: string;
  value: any;
  onChange: (id: string, val: any) => void;
}) => {
  useEffect(() => {
    if (id) onChange(id, new Date());
  }, []);

  if (flags.includes(Flag.hidden_from_kiosk)) return null;

  return (
    <ListItem>
      <Text size={"sm"} marginBottom=".5rem">
        {name}
        {flags.includes(Flag.required) && <span color="red">*</span>}
      </Text>
      <FormControl>
        <DatePicker
          showTimeSelect
          selected={value}
          onChange={(date: Date) => onChange(id, date.toISOString())}
          showPopperArrow={true}
          dateFormat="Pp"
        />
      </FormControl>
    </ListItem>
  );
};

const ListField = ({
  id,
  name,
  value,
  onChange,
  listOptions,
  flags,
}: {
  flags: Flag[];
  id: string;
  name: string;
  value: any;
  onChange: (id: string, val: any) => void;
  listOptions: string[];
}) => {
  if (flags.includes(Flag.hidden_from_kiosk)) return null;

  return (
    <ListItem>
      <Text size={"sm"} marginBottom=".5rem">
        {name}
        {flags.includes(Flag.required) && <span color="red">*</span>}
      </Text>
      <RadioGroup
        onChange={(val) => onChange(id, val.toString())}
        value={value}
      >
        <Stack>
          {listOptions?.map((option) => (
            <Radio size="md" value={option} colorScheme="blue" key={option}>
              {option}
            </Radio>
          ))}
        </Stack>
      </RadioGroup>
    </ListItem>
  );
};

const MultiListField = ({
  id,
  name,
  value,
  onChange,
  listOptions,
  flags,
}: {
  flags: Flag[];
  id: string;
  name: string;
  value: any;
  onChange: (id: string, val: any) => void;
  listOptions: string[];
}) => {
  const handleChange = (option: string) => {
    const next = xor(value, [option]);
    onChange(id, next);
  };

  if (flags.includes(Flag.hidden_from_kiosk)) return null;

  return (
    <ListItem>
      <Text size={"sm"} marginBottom=".5rem">
        {name}
        {flags.includes(Flag.required) && <span color="red">*</span>}
      </Text>
      <CheckboxGroup>
        <Stack>
          {listOptions?.map((option) => (
            <Checkbox
              size="md"
              value={option}
              colorScheme="blue"
              key={option + "multi"}
              onChange={() => handleChange(option)}
            >
              {option}
            </Checkbox>
          ))}
        </Stack>
      </CheckboxGroup>
    </ListItem>
  );
};

interface Option {
  readonly label: string;
  readonly value: string;
}

const createOption = (label: string) => ({
  label,
  value: label,
});

interface State {
  readonly inputValue: string;
  readonly value: readonly Option[];
}

const SignatureField = ({
  id,
  name,
  value,
  onChange,
  listOptions,
  flags,
}: {
  flags: Flag[];
  id: string;
  name: string;
  value: any;
  onChange: (id: string, val: any) => void;
  listOptions: string[];
}) => {
  const [text, setText] = useState("");

  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (event) => {
    if (!text) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
        onChange(id, text);
        event.preventDefault();
    }
  };

  if (flags.includes(Flag.hidden_from_kiosk)) return null;

  return (
    <ListItem>
      <Text size={"sm"} marginBottom=".5rem">
        {name}
        {flags.includes(Flag.required) && <span color="red">*</span>}
      </Text>
      <InputGroup size="md" borderColor={value ? "green.400" : "gray.200"}>
        <Input
          placeholder="Type a signature and press enter..."
          onChange={(e) => {
            setText(e.target.value);
          }}
          onKeyDown={handleKeyDown}
          value={text}
        />
        {text.length && (
          <InputRightAddon
            children={
              <CloseIcon
                onClick={() => {
                  setText("");
                  onChange(id, null);
                }}
              />
            }
          />
        )}
      </InputGroup>
    </ListItem>
  );
};

const SignaturesField = ({
  id,
  name,
  value,
  onChange,
  listOptions,
  flags,
}: {
  flags: Flag[];
  id: string;
  name: string;
  value: any;
  onChange: (id: string, val: any) => void;
  listOptions: string[];
}) => {
  if (flags.includes(Flag.hidden_from_kiosk)) return null;

  return (
    <ListItem>
      <Text size={"sm"} marginBottom=".5rem">
        {name}
        {flags.includes(Flag.required) && <span color="red">*</span>}
      </Text>
      <Creatable
        isMulti
        isClearable
        placeholder="Type a signature and press enter..."
        components={{
          DropdownIndicator: null,
        }}
        onChange={(val) => {
          onChange(id, val);
        }}
        value={value}
      />
    </ListItem>
  );
};
